// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { EXPIRATION_CUTOFF } from "./constant";
import { CurrentAptosName } from "./types";

export function isEligibleForFreeRenewal(domain: CurrentAptosName): boolean {
  const cutoffDate = new Date(EXPIRATION_CUTOFF);
  const expirationDate = new Date(domain.expiration_timestamp as string);

  return expirationDate < cutoffDate;
}
