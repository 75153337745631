// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQuery } from "@tanstack/react-query";

import { indexer } from "../../utils/indexer";
import { CurrentAptosName } from "../../utils/types";

export function useDomain({
  owner,
  domain,
  subdomain,
  enabled,
}: {
  owner: string;
  domain: string;
  subdomain?: string;
  enabled: boolean;
}) {
  return useQuery({
    enabled,
    queryKey: ["domain", owner, domain, subdomain],
    staleTime: 5 * 60 * 1000,
    queryFn: async () => {
      try {
        const res = await indexer.get_domain({
          domain,
          subdomain: subdomain ?? "",
          owner_address: owner,
        });

        // TODO: Remove `as CurrentAptosName` when the indexer is updated
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return (res.current_aptos_names[0] as CurrentAptosName) ?? null;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
  });
}
