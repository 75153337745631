// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Button, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";

import { useDomainActions } from "../../../../../../context/hooks/useDomainActions";
import { parseTimestampNoMillisecond } from "../../../../../../utils";
import { getExpiration } from "../../../../../../utils/getExpiration";
import { getExpirationStatus } from "../../../../../../utils/getExpirationStatus";
import { isRenewable } from "../../../../../../utils/isRenewable";
import { CurrentAptosName } from "../../../../../../utils/types";
import { InGraceTooltip } from "./InGraceTooltip";
import { ManageSectionTitle } from "./ManageSectionTitle";
import { UpdateTargetAddressInput } from "./UpdateTargetAddressInput";
import { UpgradeParentTooltip } from "./UpgradeParentTooltip";

function ManageSection({
  title,
  tooltip,
  children,
}: React.PropsWithChildren<{ title: string; tooltip: string }>) {
  return (
    <Stack gap={1} alignItems="stretch" sx={{ width: "100%", maxWidth: "830px" }}>
      <ManageSectionTitle title={title} tooltip={tooltip} />
      <Stack flexDirection="row" gap={2} alignItems="center">
        {children}
      </Stack>
    </Stack>
  );
}

export function ManageTab({
  ansRecord,
  domain,
}: {
  ansRecord: CurrentAptosName;
  domain?: CurrentAptosName | null;
}) {
  const {
    clearPrimary,
    setPrimary,
    setTargetAddress,
    migrateDomainName,
    renewOrMigrateDomain,
    transactionInProcess,
  } = useDomainActions();

  const isSubdomain = !!ansRecord.subdomain;
  const isPetraName = ansRecord.domain === "petra";
  const isPrimary = ansRecord.is_primary;
  const isV1 = ansRecord.token_standard === "v1";
  const isParentV1 = domain?.token_standard === "v1";
  const renewable = isRenewable(ansRecord);
  const { domain: domainName, subdomain: subdomainName } = ansRecord;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const expiration_status = getExpirationStatus(ansRecord.expiration_timestamp);
  const isInGrace = expiration_status === "in_grace";

  const togglePrimary = async () => {
    if (!isPrimary) {
      await setPrimary(ansRecord);
    } else {
      await clearPrimary();
    }
  };

  const updateTargetAddress = async (address: string) => {
    await setTargetAddress(ansRecord, address);
  };

  const updateToV2 = async () => {
    await migrateDomainName(domainName, subdomainName);
  };

  const onRenewClick = async () => {
    await renewOrMigrateDomain(ansRecord);
  };

  const name = useMemo<string>(() => {
    return [subdomainName, domainName].filter((x) => x).join(".");
  }, [domainName, subdomainName]);

  return (
    <Stack gap={3} px={{ xs: 2, md: 0 }}>
      <ManageSection
        title="primary name"
        tooltip={
          ansRecord.is_primary
            ? `${name}.apt is the default name for this wallet across the Aptos ecosystem.`
            : `Make ${name}.apt the default name for this wallet across the Aptos ecosystem.`
        }
      >
        {isPrimary ? (
          <Typography>'{name}.apt' is set as your primary name.</Typography>
        ) : (
          <Typography>'{name}.apt' is not your primary name.</Typography>
        )}

        <InGraceTooltip inGrace={isInGrace}>
          <UpgradeParentTooltip isParentV1={isParentV1} isSubdomain={isSubdomain}>
            <Button
              variant="contained"
              color={isPrimary ? "error" : "primary"}
              onClick={togglePrimary}
              disabled={transactionInProcess || (isParentV1 && isSubdomain) || isInGrace}
              sx={{ flexShrink: 0 }}
            >
              {isPrimary ? "Clear Primary Name" : "Set As Primary"}
            </Button>
          </UpgradeParentTooltip>
        </InGraceTooltip>
      </ManageSection>

      <ManageSection
        title="expiration"
        tooltip="Your name is registered until its expiration date. To maintain ownership, you have the ability to renew a name's registration starting 6 months before and up to 30 days past expiry."
      >
        <Typography variant="stats">
          {isPetraName ? "-" : parseTimestampNoMillisecond(getExpiration(ansRecord) + "Z")}
        </Typography>

        {!isSubdomain && (
          <Button
            variant="contained"
            onClick={onRenewClick}
            disabled={transactionInProcess || !renewable}
            sx={{ flexShrink: 0 }}
          >
            Renew Name for 1 Year
          </Button>
        )}
      </ManageSection>

      <ManageSection title="Target Address" tooltip="Set the address that the name points to.">
        <UpdateTargetAddressInput
          updateTargetAddress={updateTargetAddress}
          isParentV1={isParentV1}
          isSubdomain={isSubdomain}
          ansRecord={ansRecord}
          disabled={transactionInProcess}
        />
      </ManageSection>

      {isV1 && (
        <ManageSection
          title="ANS Token v2"
          tooltip="ANS token v2 employs the Aptos Digital Asset standard, Aptos' latest in flexibility, composability, and scalability."
        >
          <Typography>
            Your name was minted as an ANS V1 token. Upgrade your name to an ANS V2 token for
            enhanced ANS features.
          </Typography>
          <UpgradeParentTooltip isSubdomain={isSubdomain} isParentV1={isParentV1}>
            <Button
              variant="contained"
              onClick={updateToV2}
              disabled={transactionInProcess || (isSubdomain && isParentV1)}
              sx={{ flexShrink: 0 }}
            >
              Upgrade to ans v2
            </Button>
          </UpgradeParentTooltip>
        </ManageSection>
      )}
    </Stack>
  );
}
