// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { InfoOutlined } from "@mui/icons-material";
import { Chip, ChipProps, Tooltip, useTheme } from "@mui/material";

import { useIsDesktop } from "../../../context/hooks/useIsDesktop";
import { CurrentAptosName } from "../../../utils/types";

interface UpgradeChipProps {
  ansRecord: CurrentAptosName;
  size?: ChipProps["size"];
}

export const UpgradeChip = ({ ansRecord, size }: UpgradeChipProps) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  if (ansRecord.token_standard !== "v1") return null;
  return (
    <Tooltip title="Your name was minted as an ANS v1 token. Upgrade your name to an ANS v2 token for enhanced ANS features">
      <Chip
        label={isDesktop ? "upgrade to v2" : undefined}
        variant="outlined"
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={() => {}}
        icon={<InfoOutlined />}
        size={size}
        sx={{
          flex: "0 0 auto",
          border: "none",
          "&>span:empty": { display: "none" },
          [theme.breakpoints.down("md")]: {
            "&>svg": {
              marginLeft: "0px!important",
              marginRight: "0px!important",
            },
          },
        }}
      />
    </Tooltip>
  );
};
