// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { LoadingModal } from "../../../../../../../../components/LoadingModal";
import { useDomainActions } from "../../../../../../../../context/hooks/useDomainActions";
import { isValidDomainName } from "../../../../../../../../utils";
import { CurrentAptosName } from "../../../../../../../../utils/types";
import { ManageSectionTitle } from "../../ManageSectionTitle";

interface RegisterSubdomainProps {
  domain: CurrentAptosName;
}

export function RegisterSubdomain({ domain }: RegisterSubdomainProps): JSX.Element {
  const theme = useTheme();
  const [subdomainName, setSubdomainName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const { registerSubdomain, transactionInProcess } = useDomainActions();

  const domainName = domain.domain;
  const isV1 = domain.token_standard === "v1";

  const onRegisterSubdomainClick = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isV1) return;

    if (!isValidDomainName(subdomainName)) {
      setNameError(true);
      return;
    }
    await registerSubdomain({
      domain,
      subdomain: subdomainName,
    });
    setSubdomainName("");
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (nameError) {
      setNameError(false);
    }
    const name = e.target.value;
    setSubdomainName(name);
  };

  return (
    <>
      <LoadingModal open={transactionInProcess} />

      <Stack gap={1} px={{ xs: 2, md: 0 }}>
        <ManageSectionTitle
          title="Add Subdomain"
          tooltip="The owner of a domain can mint an unlimited amount of subdomains associated with their domain. Subdomains do not carry a registration fee and can be managed by the domain owner."
        />

        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          component="form"
          onSubmit={onRegisterSubdomainClick}
          gap={1}
        >
          <TextField
            value={subdomainName}
            id="outlined-basic"
            disabled={transactionInProcess}
            variant="outlined"
            autoComplete="off"
            type="text"
            onInput={onInputChange}
            fullWidth
            InputProps={{
              style: {
                padding: 0,
                height: "45px",
              },
              endAdornment: (
                <InputAdornment position="end" sx={{ paddingRight: 1 }}>
                  <Typography variant="stats">.{domainName}.apt</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip
            disableFocusListener={!isV1}
            disableHoverListener={!isV1}
            disableInteractive={!isV1}
            disableTouchListener={!isV1}
            title="Please upgrade this domain to ANS v2 to enable new subdomain registration."
          >
            <Box sx={{ flexShrink: 0 }}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                disabled={transactionInProcess || isV1}
                sx={{ width: "100%" }}
              >
                Add Subdomain
              </Button>
            </Box>
          </Tooltip>
        </Stack>

        {!!subdomainName.length && !isValidDomainName(subdomainName) && (
          <Box
            component="ul"
            sx={{
              margin: 0,
              paddingLeft: 1,
              listStylePosition: "inside",
              color: nameError ? theme.palette.error.main : theme.palette.text.disabled,
            }}
          >
            <Typography
              variant="stats"
              sx={{ fontSize: "0.75rem", color: "inherit" }}
              component="li"
            >
              Cannot start or end with a hyphen (-)
            </Typography>
            <Typography
              variant="stats"
              sx={{ fontSize: "0.75rem", color: "inherit" }}
              component="li"
            >
              Contains only letters (a-z), numbers (0-9), or a hyphen (-)
            </Typography>
            <Typography
              variant="stats"
              sx={{ fontSize: "0.75rem", color: "inherit" }}
              component="li"
            >
              Must be between 3-63 characters
            </Typography>
          </Box>
        )}
      </Stack>
    </>
  );
}
