// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { BEGINNING_OF_TIME, END_OF_TIME, RENEWAL_PAGE_LIMIT } from "../../utils/constant";
import { indexer } from "../../utils/indexer";
import { makeFetchAll } from "../../utils/makeFetchAll";
import { CurrentAptosName } from "../../utils/types";

const stripTime = (date: number) => new Date(new Date(date).toDateString());

export function useDomains({
  limit = RENEWAL_PAGE_LIMIT,
  expiration_gte = BEGINNING_OF_TIME.valueOf(),
  expiration_lte = END_OF_TIME.valueOf(),
  token_standard = ["v1", "v2"],
  owner = "",
}: {
  limit?: number;
  expiration_gte?: number;
  expiration_lte?: number;
  token_standard?: Array<"v1" | "v2">;
  owner: string;
}) {
  const [page, setPage] = useState(0);

  const { data: total = 0, isLoading: isTotalLoading } = useQuery({
    queryKey: [
      "domains",
      "total",
      owner,
      stripTime(expiration_lte),
      stripTime(expiration_gte),
      token_standard,
    ],
    placeholderData: (x: number | undefined) => x,
    enabled: !!owner,
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const totalRes = await indexer.get_domains_count({
          owner_address: owner,
          expiration_lte: new Date(expiration_lte).toISOString(),
          expiration_gte: new Date(expiration_gte).toISOString(),
          token_standard,
        });

        return totalRes.current_aptos_names_aggregate.aggregate?.count ?? 0;
      } catch (e) {
        console.warn(e);
        return 0;
      }
    },
  });

  const { data: names = [], isLoading: isNamesLoading } = useQuery({
    queryKey: [
      "domains",
      page,
      owner,
      stripTime(expiration_lte),
      stripTime(expiration_gte),
      token_standard,
    ],
    placeholderData: (x: Array<CurrentAptosName> | undefined) => x,
    enabled: !!owner,
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const namesRes = await indexer.get_domains({
          owner_address: owner,
          offset: page * limit,
          expiration_lte: new Date(expiration_lte).toISOString(),
          expiration_gte: new Date(expiration_gte).toISOString(),
          limit,
          token_standard,
        });

        // TODO: Remove `as CurrentAptosName[]` when the indexer is updated
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const names = (namesRes.current_aptos_names ?? []) as Array<CurrentAptosName>;

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return names ?? [];
      } catch (e) {
        console.warn(e);
        return [];
      }
    },
  });

  const isLoading = isNamesLoading || isTotalLoading;

  function changePage(i: number) {
    if (isLoading) return;
    // Make sure the page is above 0 and below the total number of pages
    const clampedPage = Math.min(Math.max(0, i), Math.ceil(total / limit));
    setPage(clampedPage);
  }

  const fetchAll = makeFetchAll(async (offset: number) => {
    const data = await indexer.get_domains({
      owner_address: owner,
      token_standard,
      offset,
      limit,
      expiration_gte: new Date(expiration_gte).toISOString(),
      expiration_lte: new Date(expiration_lte).toISOString(),
    });

    return data.current_aptos_names as Array<CurrentAptosName>;
  });

  return {
    changePage,
    loading: isLoading,
    names,
    page,
    total,
    fetchAll,
  };
}
