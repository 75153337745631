// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { RENEWAL_MONTHS_WINDOW } from "./constant";
import { getExpirationStatus } from "./getExpirationStatus";
import { CurrentAptosName } from "./types";

export function isRenewable(domain: CurrentAptosName): boolean {
  const domainExpiration = new Date(domain.expiration_timestamp as string);
  const status = getExpirationStatus(domain.expiration_timestamp as string);

  const inRenewWindow = new Date();
  inRenewWindow.setMonth(inRenewWindow.getMonth() + RENEWAL_MONTHS_WINDOW);

  return status === "in_grace" || domainExpiration < inRenewWindow;
}
