// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useState } from "react";

import { fontFamilies } from "../../../../../../themes/theme";
import { isValidAccountAddress } from "../../../../../../utils";
import { CurrentAptosName } from "../../../../../../utils/types";
import { UpgradeParentTooltip } from "./UpgradeParentTooltip";

interface UpdateTargetAddressInputProps {
  ansRecord: CurrentAptosName;
  isSubdomain: boolean;
  isParentV1: boolean;
  updateTargetAddress: (newAddress: string) => Promise<void>;
  disabled?: boolean;
}

export function UpdateTargetAddressInput({
  ansRecord,
  isParentV1,
  isSubdomain,
  updateTargetAddress,
  disabled,
}: UpdateTargetAddressInputProps): JSX.Element {
  const [newAddressError, setNewAddressError] = useState<boolean>(false);
  const [newAddress, setNewAddressInput] = useState<string>("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.value;
    setNewAddressInput(name);
  };

  const onUpdateClick = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (disabled) return;

    if ((!newAddress.length && ansRecord.registered_address) || isValidAccountAddress(newAddress)) {
      setNewAddressError(false);
      await updateTargetAddress(newAddress);
      setNewAddressInput("");
    } else {
      setNewAddressError(true);
    }
  };

  return (
    <Box component="form" sx={{ width: "100%" }}>
      <Stack direction="row" gap={2} justifyContent="stretch">
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder={ansRecord.registered_address}
          value={newAddress}
          autoComplete="off"
          type="text"
          onInput={onInputChange}
          fullWidth
          sx={{ width: "100%", flex: "1 1 100%" }}
          InputProps={{
            style: {
              padding: 0,
              height: "45px",
              fontFamily: fontFamilies.mono,
              fontSize: "0.875rem",
              width: "100%",
            },
          }}
        />
        <UpgradeParentTooltip isParentV1={isParentV1} isSubdomain={isSubdomain}>
          <Button
            type="submit"
            variant="contained"
            onClick={onUpdateClick}
            disabled={disabled || (isParentV1 && isSubdomain)}
            color={!newAddress.length && ansRecord.registered_address ? "error" : "primary"}
            sx={{ flexShrink: 0, height: "100%" }}
          >
            {!newAddress.length && ansRecord.registered_address
              ? "Clear Target Address"
              : "Set Target Address"}
          </Button>
        </UpgradeParentTooltip>
      </Stack>
      {newAddressError && (
        <Typography variant="subtitle2" gutterBottom color={red[500]} mt={2}>
          Invalid Address
        </Typography>
      )}
    </Box>
  );
}
