// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useCallback } from "react";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag?: (...args: Array<any>) => void;
  }
}

interface InternalEvents {
  // TXN Based Events
  bulk_upgrade: {
    txn_hash?: string;
    ip: string;
    success: boolean;
    names: Array<string>;
  };
  bulk_renew: {
    txn_hash?: string;
    ip: string;
    success: boolean;
    names: Array<string>;
    upgradedNames: Array<string>;
  };
  single_upgrade: {
    txn_hash?: string;
    ip: string;
    success: boolean;
    name: string;
  };
  single_renew: { txn_hash?: string; ip: string; success: boolean; name: string };
  registered_subdomain: {
    txn_hash?: string;
    ip: string;
    success: boolean;
    name: string;
  };
  registered_domain: {
    txn_hash?: string;
    ip: string;
    success: boolean;
    name: string;
  };
  promo_renewal: {
    total_eligible: number;
    total_ineligible: number;
    total_transacted: number;
    txn_hash?: string;
    ip: string;
    names: Array<string>;
    success: boolean;
  };
  // Wallet Events
  wallet_connected: { wallet_name: string };
  // UI Events
  banner_closed: { banner_id: string };
  banner_cta_clicked: { banner_id: string };
}

const NETWORK_KEY = "network";

export function useSendAnalytic() {
  const { network } = useWallet();
  const networkName = network?.name.toLocaleLowerCase() ?? "";

  return useCallback(
    function <EventName extends keyof InternalEvents>(
      ...[eventName, eventProperties]: InternalEvents[EventName] extends undefined
        ? [EventName]
        : [EventName, InternalEvents[EventName]]
    ) {
      if (window.gtag) {
        const properties: Record<string, unknown> = {
          [NETWORK_KEY]: networkName,
        };

        if (eventProperties) {
          for (const key in eventProperties) {
            if (key === NETWORK_KEY) {
              console.warn(`Cannot use ${NETWORK_KEY} as an event property`);
              continue;
            }

            properties[key] = sanitizeHash(eventProperties[key]);
          }
        }

        window.gtag("event", eventName, { ...properties, send_to: "G-0PMZ9GNT1Z" });
      }
    },
    [networkName],
  );
}

/**
 * Google analytics is automatically converting hash values to
 * doubles, stripping the 0x prefix seems to help.
 */
function sanitizeHash(val?: unknown) {
  if (!!val && typeof val === "string" && /^0x[0-9a-fA-F]{64}$/.test(val)) {
    return val.slice(2);
  }

  return val;
}
