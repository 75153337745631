// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";

import { Link } from "../../../components/Link";
import { useGlobalState } from "../../../context";

const H1Section = ({ children }: { children: string }) => (
  <Typography component="h1" variant="h1" mb={3}>
    {children}
  </Typography>
);

const H3Section = ({ children }: { children: React.ReactNode }) => (
  <Typography component="h3" variant="h5" mb={2}>
    {children}
  </Typography>
);

export function FAQPage() {
  const state = useGlobalState();
  const network = state.networkSupport;

  if (network != "mainnet" && network != "testnet") {
    return null;
  }

  return (
    <Container sx={{ pt: 12 }}>
      <Box>
        <H1Section>FAQ</H1Section>
        <Stack spacing={3}>
          {faqs.map((faq, index) => (
            <Box key={index}>
              <H3Section>{faq.question}</H3Section>
              {faq.answers.map((answer, index) => (
                <Typography key={index} variant="body1">
                  {answer}
                </Typography>
              ))}
            </Box>
          ))}
        </Stack>
      </Box>
    </Container>
  );
}

interface FAQ {
  question: React.ReactNode;
  answers: Array<React.ReactNode>;
}

const faqs: Array<FAQ> = [
  {
    question: (
      <>
        What is ANS V2? ANS V2 makes use of the{" "}
        <Link to="https://aptosfoundation.org/currents/aptos-digital-asset-standard">
          Aptos Digital Asset Standard
        </Link>{" "}
        (DA).
      </>
    ),
    answers: [
      `DA allows ANS to utilize the Aptos Object model, reducing gas costs due to more efficient storage utilization and improved data access. Use of DA will allow us to continue supporting interoperability as ANS domains will be forward compatible while the ecosystem updates support to V2 tokens.`,
    ],
  },
  {
    question: "What’s new in ANS V2? ANS V2 introduces enhanced functionality.",
    answers: [
      `Renewals. Domains can now be renewed for an additional one year of registration. See more details in the FAQs below.`,
      `Subdomain management. Domains now have greater control over subdomains including the ability to make a subdomain soulbound, setting and changing subdomain expiration policies, and recalling/transferring subdomains. Due to these changes, ANS domains are now contained in two collections: Aptos Domain Names V2 and Aptos Subdomain Names V2. While these features will be added to aptosnames.com and our in-development SDK over time, they are available immediately through the ANS V2 smart contract.`,
    ],
  },
  {
    question: `When and how can I renew my name?`,
    answers: [
      <>
        A name can be renewed up to 6 months before its expiration date and for up to 30 days after
        it expires. A name may be renewed through aptosnames.com by navigating to the{" "}
        <Link to="/account">‘Account’</Link> page and using the action menu (3 dots) within the name
        table. You may renew all your names at once by using the action menu at the top right corner
        of the table to ‘Renew All Expiring Soon’.
      </>,
    ],
  },
  {
    question: "How much does it cost to renew my name?",
    answers: [
      "Similar to new registrations, the pricing structure for renewals is also based on the length of the name - 3 characters: 20 APT; 4 characters: 10 APT; 5 characters: 5 APT; 6+ characters: 1 APT. If you do not have enough funds in your account before initiating a renewal, you will need to fund your account with enough APT to cover the renewal fee + gas before the transaction will be successful.",
    ],
  },
  {
    question: `What does ‘Upgrade to ANS V2’ mean?`,
    answers: [
      "Aptos Names is providing a migration service that allows users to upgrade all existing ANS domains. All name holders that want to take advantage of the new ANS V2 features can upgrade their names to V2 either with the “Upgrade to ANS V2” action on the ANS account page, or as a by-product of other name actions like name renewals, primary name changes, and more. Please note that all new ANS registrations will be V2 and support for V1 will eventually be deprecated after the last V1 names have expired. You will not be charged for any upgrades, but you will be responsible for any gas fees.",
    ],
  },
  {
    question: `What happens if I don’t renew my name before it expires?`,
    answers: [
      `We know life can get busy and deadlines can be forgotten, so we are offering a 30-day renewal grace period to all name owners. You’ll have  an additional 30 days after your name expiration date to renew it before it becomes available for others to register. During the 30-day grace period, your name will not be usable and will be labeled as expired in your ANS account, but function will be restored as soon as it is renewed. Registration renewals are applied from the name’s expiration date.`,
    ],
  },
  {
    question: `I’m renewing my name, how long is it renewed for?`,
    answers: [
      `For now, names can only be registered for one year at a time. Registration renewals are applied from the name’s expiration date. So, when you renew your name, an additional one year will be added to the current expiration date regardless of the day you renew your name on.`,
    ],
  },
  {
    question: `Can I renew subdomains?`,
    answers: [
      `In ANS V2, subdomains can be managed by domain owners and all subdomains registered through aptosnames.com will follow the expiration dates of their associated domains. So, while subdomains are not able to be renewed individually, you are able to keep your subdomains active by keeping your domains registered. Subdomains kept in V1 will expire on their expiration dates, while subdomains minted in V2 will inherit the expiration date of their associated domains.`,
    ],
  },
];
