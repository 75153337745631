// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box, Tooltip } from "@mui/material";

export function UpgradeParentTooltip({
  children,
  isParentV1,
  isSubdomain,
}: {
  children: React.ReactElement;
  isParentV1: boolean;
  isSubdomain: boolean;
}) {
  const disabled = !(isParentV1 && isSubdomain);
  return (
    <Tooltip
      disableInteractive={disabled}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      title="To perform this action, first upgrade the parent domain to v2."
    >
      <Box sx={{ flexShrink: 0 }}>{children}</Box>
    </Tooltip>
  );
}
