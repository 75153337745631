// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { EXPIRES_SOON_MONTHS_WINDOW, GRACE_PERIOD_IN_DAYS } from "./constant";

export type ExpirationStatus = "in_grace" | "expired" | "expires_soon" | "not_expired";

const now = () => new Date();

export const getExpirationKeyDates = (keyDate: ExpirationStatus): Date => {
  if (keyDate === "in_grace" || keyDate === "expired") {
    return new Date(now().setDate(now().getDate() - GRACE_PERIOD_IN_DAYS));
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (keyDate === "expires_soon" || keyDate === "not_expired") {
    return new Date(now().setMonth(now().getMonth() + EXPIRES_SOON_MONTHS_WINDOW));
  }

  throw new Error("Invalid Key Date");
};

export function getExpirationStatus(expiration_timestamp: string): ExpirationStatus {
  const ansExpirationDate = new Date(expiration_timestamp);
  const inGraceDate = getExpirationKeyDates("in_grace");
  const expiresSoonDate = getExpirationKeyDates("expires_soon");

  if (ansExpirationDate > expiresSoonDate) return "not_expired";
  if (ansExpirationDate < expiresSoonDate && ansExpirationDate > now()) return "expires_soon";

  if (now() < ansExpirationDate && ansExpirationDate > expiresSoonDate) return "not_expired";
  if (now() > ansExpirationDate && ansExpirationDate < inGraceDate) return "expired";
  if (now() > ansExpirationDate && ansExpirationDate >= inGraceDate) return "in_grace";

  return "ERROR" as ExpirationStatus;
}
