// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { SubdomainExpirationPolicy } from "./constant";
import { CurrentAptosName } from "./types";

export function getExpiration(name: CurrentAptosName): string {
  const isSubdomain = !!name.subdomain;
  const expirationPolicy = name.subdomain_expiration_policy as SubdomainExpirationPolicy;
  const expiration =
    isSubdomain && expirationPolicy === SubdomainExpirationPolicy.FollowsDomain
      ? (name.domain_expiration_timestamp as string)
      : (name.expiration_timestamp as string);

  return expiration;
}
