// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Stack } from "@mui/material";
import { useEffect } from "react";

import { useGlobalState } from "../../../../../../../context";
import { CurrentAptosName } from "../../../../../../../utils/types";
import { AnsTable } from "../../../../../_components/AnsTable";
import { RegisterSubdomain } from "./_components/RegisterSubdomain";

interface SubdomainsTabProps {
  domain: CurrentAptosName;
  subdomains: Array<CurrentAptosName>;
  total: number;
  changePage: (page: number) => void;
  page: number;
  fetchAll: () => Promise<Array<CurrentAptosName>>;
  isLoading?: boolean;
}

export function SubdomainsTab({
  domain,
  subdomains,
  total,
  page,
  changePage,
  isLoading,
}: SubdomainsTabProps) {
  const state = useGlobalState();
  const { account, connected } = useWallet();
  const domainName = domain.domain;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (state && account?.address) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetch(domainName);
    }
  }, [connected, state, domainName]);

  return (
    <Stack gap={3}>
      <RegisterSubdomain domain={domain} />

      {subdomains.length > 0 && (
        <AnsTable
          ansRecords={subdomains}
          changePage={changePage}
          page={page}
          total={total}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
}
