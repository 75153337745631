// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQuery } from "@tanstack/react-query";

import { getExpirationKeyDates } from "../../utils/getExpirationStatus";
import { indexer } from "../../utils/indexer";

const expiration_timestamp = getExpirationKeyDates("expired");

export function useDomainSearch(domainName: string) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["domain-search", domainName],
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const res = await indexer.domain_availability_search({
          domain: domainName,
          expiration_timestamp: expiration_timestamp.toISOString(),
        });

        return res.current_ans_lookup_v2[0] ?? null;
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
  });

  return { domain: data, loading: isLoading, error };
}
